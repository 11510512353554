<template>
  <div class="rule-dialog">
    <b-modal id="modalNewNotification" :ok-title="$t(acceptText)" size="lg" :title="$t('Notificación')" ok-variant="warning" 
      ok-only no-close-on-esc no-close-on-backdrop centered @close="close" @ok="ok" :ok-disabled="loading.all">
      <form-render :form.sync="form" :fields="fields" @send="updateOrCreate" ref="formRenderNewNotification" :key="keyFormRender">
        <template #companies>
          <div class="row">
            <div class="col-md-12 companies">
              <label title="Empresa" class="label-form"><span>Empresa</span></label>
                <v-select
                  v-model="form.companies"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Seleccione empresa"
                  label="text"
                  :options="formattedOrganization"
                  :disabled="loading.companies"
                  @option:deselecting="onDeselect"
                  @input="changeCompany('prueba', form.companies)"
                  :multiple="true"
                />
            </div>
          </div>
        </template>
      </form-render>
    </b-modal>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
export default {
  name: 'modal-notification',
  components: { vSelect },
  props: ['data', 'syncNotifications'],
  data () {
    return {
      content: '',
      acceptText: 'Crear',
      title: '',
      form: {},
      company: {},
      today : new Date(),
      fields: [],
      loading: {
        all: false,
        companies: false,
        shipper_id: false
      },
      optionsType: [
        { id: 'companies', text: 'Empresas'},
        { id: 'onlyCompanyId_companies', text: 'Ids de seller'},
        { id: 'onlyCompanyId_marketplaces', text: 'Ids de marketplaces'},
        { id: 'onlyCompanyId_sellersByMarketplaces', text: 'Sellers por ids de marketplaces'}
      ],
      optionsExcludeType: [
        { id: 'onlyCompanyId_companies', text: 'Ids de empresas'},
        { id: 'onlyCompanyId_sellersByMarketplaces', text: 'Sellers por ids de marketplaces'},
        { id: 'onlyCompanyId_allSellersMarketplaces', text: 'Todos los sellers de marketplaces'}
      ],
      optionsCountries: [
        { id: '*', text: 'Todos', code: 'all' },
        { id: 1, text: 'Chile', code: 'CL' },
        { id: 2, text: 'México', code: 'MX' },
        { id: 3, text: 'Colombia', code: 'CO' },
        { id: 4, text: 'Perú', code: 'PE' },
        { id: 5, text: 'Argentina', code: 'AR' },
        { id: 6, text: 'Ecuador', code: 'EC' }
      ],
      optionsCategory: [
        {id: 'category_update', text: 'Actualización Condiciones', color: 'info'},
        {id: 'category_alert', text: 'Alerta Operacional', color: 'warning'},
        {id: 'category_new', text: 'Nuevo', color: 'success'}
      ],
      optionsAccount: [
        {id: 'distribution', text: 'Distribución', name: 'distribucion'},
        {id: 'gateway', text: 'Pasarela', name: 'pasarela' }
      ],
      optionsBilling: [
        {id: true, text: 'Si'},
        {id: false, text: 'No'}
      ],
      keyFormRender: 0,
      baseService: new BaseServices(this),
      formattedShipper: [],
      carrierssByShipOrg: [],
      deleteShipperFromArray: {
        click: false
      },
      indexShipper: null
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      organizations: 'getOrganizationsGlobal',
      carriers: 'getCarriersNotifications'
    })
  },
  watch: {
    data () { 
      this.acceptText = !!this.data.id ? 'Guardar' : 'Crear'
      this.form = {
        ...this.data,
        enabling: this.data.enabling ? new Date(this.data.enabling) : null,
        expiration: this.data.expiration ? new Date(this.data.expiration) : null

      }
      this.fields = this.fields.filter(el => !['companies', 'onlyCompanyId', 'exclude_onlyCompanyId'].includes(el.name))
      this.indexShipper.containerClass = 'hidden' 
      this.formattedShipper = []
      this.setSelectOptions('carriers', { options: this.carriers })
      if (!!this.data.id) {
        this.changeType('type', this.form.type)
        this.changeExcludeType('exclude_type', this.form.exclude_type)
        if (this.data?.companies.length > 0) this.changeCompany('companies', this.form.companies)
      }
    },
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    organizations () {
      this.formattedOrganization = this.organizations.map(el => {
        if (el.organization_type.id === 1) {
          return {...el, text: `${el.name} (M)`}
        } else {
          return {...el, text: el.name}
        }
      })
      this.setSelectOptions('companies', { options: this.formattedOrganization })
      this.loading.all = false
    },
    carriers () {
      this.setSelectOptions('carriers', { options: this.carriers })
      this.loading.all = false
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.setFields()
      if (this.organizations && !!this.organizations.length) {
        this.formattedOrganization = this.organizations.map(el => {
          if (el.organization_type.id === 1) {
            return {...el, text: `${el.name} (M)`}
          } else {
            return {...el, text: el.name}
          }
        })
        this.setSelectOptions('companies', { options: this.formattedOrganization })
        this.loading.all = false
      }
      if (this.carriers && !!this.carriers.length) {
        this.setSelectOptions('carriers', { options: this.carriers })
        this.loading.all = false
      }
    },
    setFields () {
      this.fields = [
        { fieldType: 'FieldSelect', label: 'País', name: 'country', containerClass: 'col-md-6 container-info', validation: 'required', options: this.optionsCountries, change: this.changeCountry, disabled: false},
        { fieldType: 'FieldSelect', label: 'Tipo de empresa (Opcional)', name: 'type', containerClass: 'col-md-6 container-info', clearable: true,  validation: '', options: this.optionsType, change: this.changeType},
        { fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Seleccione Seller', clearable: true, useSkeleton: false, containerClass: 'hidden', multiple: true, notCloseOnSelect: true/* , change: this.changeShipper */},
        { fieldType: 'FieldSelect', label: 'Ids a excluir', name: 'exclude_type', containerClass: 'col-md-6 container-info', clearable: true,  validation: '', options: this.optionsExcludeType, change: this.changeExcludeType},
        { fieldType: 'FieldSelect', label: 'Categoría', name: 'category', containerClass: 'col-md-6 container-info', clearable: true,  validation: '', options: this.optionsCategory},
        { fieldType: 'FieldSelect', label: 'Facturación Directa', name: 'direct_billing', containerClass: 'col-md-6 container-info', clearable: true,  validation: '', options: this.optionsBilling},
        { fieldType: 'FieldSelect', label: 'Tipo de cuenta courier', name: 'account_type', containerClass: 'col-md-6 container-info', clearable: true,  validation: '', options: this.optionsAccount},
        { fieldType: 'FieldSelect', label: 'Limitar couriers', useLabel: true, name: 'carriers', containerClass: 'col-md-12 container-info', multiple: true, useSkeleton: false},
        { fieldType: 'FieldDatepicker', label: 'Fecha habilitación', name: 'enabling', containerClass: 'col-md-6 container-info', validation: '', clearable: true},
        { fieldType: 'FieldDatepicker', label: 'Fecha expiración', name: 'expiration', containerClass: 'col-md-6 container-info', validation: '', clearable: true},
        { fieldType: 'FieldInput', label: 'Título', name: 'title', containerClass: 'col-md-6 container-info', validation: 'required'},
        { fieldType: 'FieldCheckbox', name: 'inactive', containerClass: 'col-md-6 container-info mt-2', options: [{id: true, text: 'Notificación desactivada'}]},
        { fieldType: 'FieldRTE', label: 'Contenido', name: 'content', containerClass: 'col-md-12 container-info', validation: 'required'}
      ]
      this.indexShipper = this.fields.find(item => item.name === 'shipper_id')
      this.indexCarrier = this.fields.find(item => item.name === 'carriers')
      this.indexCountry = this.fields.find(item => item.name === 'country')
      this.getCarriers()
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderNewNotification.checkForm()
    },
    close () {
      this.$emit('update:data', {})
    },
    async sync() {
      await this.syncNotifications()
    },
    deleteDiacritics(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    updateOrCreate (myForm) {
      this.loading.all = true
      const dateSplit = myForm.expiration ? this.$options.filters.moment(myForm.expiration, 'DD-MM-YYYY').split('-') : null
      const enablingDate = myForm.enabling ? this.$options.filters.moment(myForm.enabling, 'DD-MM-YYYY').split('-') : null
      const formatterTitle = this.deleteDiacritics(myForm.title)
      const splitTitle = formatterTitle.toLowerCase().split(' ')
      this.carriersArray = []
      this.companiesArray = []
      this.shippersArray = []
      this.carriersCode = []
      if (myForm.carriers?.length > 0) {
        this.carriersArray = this.keywordsByType(myForm.carriers)
        this.carriersCode =  myForm.carriers.map(item => item.code)
      }
      if (myForm.companies?.length > 0) this.companiesArray = this.keywordsByType(myForm.companies)
      if (myForm.shipper_id?.length > 0) this.shippersArray = this.keywordsByType(myForm.shipper_id)
      const splitKeywords = splitTitle.concat(this.carriersArray, this.companiesArray, this.shippersArray)
      if (!this.data.id) {
        this.currentForm = {
          title: myForm.title,
          content:myForm.content,
          created: new Date().getTime(),
          country: myForm.country,
          carriers: myForm.carriers || [],
          carriersCode: this.carriersCode,
          type: myForm.type || null,
          category: myForm.category || null,
          companies: myForm.companies || [],
          shipper_id: myForm.shipper_id || [],
          onlyCompanyId: myForm.onlyCompanyId || '',
          exclude_type: myForm.exclude_type || null,
          exclude_onlyCompanyId: myForm.exclude_onlyCompanyId || '',
          inactive: !!myForm.inactive,
          expiration: dateSplit ? parseInt(this.$options.filters.moment(new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0], 23, 59, 59), 'x')) : 9999999999999,
          enabling: enablingDate ? parseInt(this.$options.filters.moment(new Date(enablingDate[2], enablingDate[1] - 1, enablingDate[0], 0, 0, 1), 'x')) : 0,
          keywordsArray: splitKeywords,
          account_type: myForm.account_type || null,
          direct_billing: myForm.direct_billing || null
        }
        this.$root.$data.notificationsCollection.add(this.currentForm)
          .then(resp => {
            this.$root.$data.currentNotificationsCollection.doc('all').update({
              data: this.$root.$data.newA(resp.id)
            })
              .then(e => {
                this.form = {}
                this.$emit('update:data', {})
                this.$emit('savedNotification', resp.id)
              })
              .catch(err => {
                this.sync()
              })
              .finally(() => {
                this.$bvModal.hide('modalNewNotification')
                this.loading.all = false
              })
          })
          .catch(err => {
            this.$alert(this.$t('msg-problema-guardar', {code: err}))
            this.loading.all = false
            console.error(err)
          })
      } else {
        this.currentForm = {
          _id: this.data.id,
          title: myForm.title,
          content:myForm.content,
          updated: new Date().getTime(),
          country: myForm.country,
          carriers: myForm.carriers || [],
          carriersCode: this.carriersCode,
          type: myForm.type || null,
          category: myForm.category || null,
          companies: myForm.companies || [],
          shipper_id: myForm.shipper_id || [],
          onlyCompanyId: myForm.onlyCompanyId || '',
          exclude_type: myForm.exclude_type || null,
          exclude_onlyCompanyId: myForm.exclude_onlyCompanyId || '',
          inactive: !!myForm.inactive,
          expiration: dateSplit ? parseInt(this.$options.filters.moment(new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0], 23, 59, 59), 'x')) : 9999999999999,
          enabling: enablingDate ? parseInt(this.$options.filters.moment(new Date(enablingDate[2], enablingDate[1] - 1, enablingDate[0], 0, 0, 1), 'x')) : 0,
          keywordsArray: splitKeywords,
          account_type: myForm.account_type || null,
          direct_billing: myForm.direct_billing || null
        }
        this.$root.$data.notificationsCollection.doc(this.data.id).update(this.currentForm)
          .then(resp => {
            this.$bvModal.hide('modalNewNotification')
            this.form = {}
            this.loading.all = false
            this.$emit('savedNotification', null)
            this.$success(this.$t('msg-exito-editar-notificacion'))
          })
          .catch(err => {
            this.$alert(this.$t('msg-problema-guardar', {code: err}))
            this.loading.all = false
            console.error(err)
          })
      } 
    },
    changeType (name, value) {
      this.fields = this.fields.filter(el => !['companies', 'onlyCompanyId'].includes(el.name))
      if (value && value.id) {
        const index = this.fields.findIndex(el => el.name === 'type')
        const myFields = {
          companies: { name: 'companies', useSlot: true, containerClass: 'col-md-12 container-info'}, 
          onlyCompanyId_companies: { fieldType: 'FieldTextarea', label: 'Ids de empresas numéricas separadas por coma (,) y sin espacios', name: 'onlyCompanyId', containerClass: 'col-md-12 container-info'},
          onlyCompanyId_marketplaces: { fieldType: 'FieldTextarea', label: 'Ids de marketplaces numéricas separadas por coma (,) y sin espacios', name: 'onlyCompanyId', containerClass: 'col-md-12 container-info'},
          onlyCompanyId_sellersByMarketplaces: { fieldType: 'FieldTextarea', label: 'Ids de marketplaces numéricas separadas por coma (,) y sin espacios', name: 'onlyCompanyId', containerClass: 'col-md-12 container-info'}
        }
        this.fields.splice(index + 1, 0, myFields[value.id])
      } 
      if (!value || value.id !== 'companies') {
        this.indexShipper.containerClass = 'hidden' 
        this.form.companies = null
        this.form.shipper_id = null
        this.formattedShipper = []
      }
      this.keyFormRender++
    },
    changeExcludeType (name, value) {
      this.fields = this.fields.filter(el => !['exclude_onlyCompanyId'].includes(el.name))
      if (value && value.id) {
        const index = this.fields.findIndex(el => el.name === 'exclude_type')
        const myFields = {
          onlyCompanyId_companies: { fieldType: 'FieldTextarea', label: 'Ids de empresas numéricas separadas por coma (,) y sin espacios', name: 'exclude_onlyCompanyId', containerClass: 'col-md-12 container-info'},
          onlyCompanyId_sellersByMarketplaces: { fieldType: 'FieldTextarea', label: 'Ids de marketplaces numéricas separadas por coma (,) y sin espacios', name: 'exclude_onlyCompanyId', containerClass: 'col-md-12 container-info'}
        }
        if (myFields[value.id]) {
          this.fields.splice(index + 1, 0, myFields[value.id])
        }
      }
      this.keyFormRender++
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },
    async changeCompany (name, value) {
      const marketplace = value.filter(item => item.organization_type.id === 1)
      if (marketplace.length === 0) this.form.shipper_id = null
      this.indexShipper.containerClass = marketplace.length > 0 ? 'col-md-12 container-info' : 'hidden'     
      const lastMKPAdded = marketplace[marketplace.length - 1]
      const lastCompanyAdded = value[value.length - 1]
      if (!this.deleteShipperFromArray.click) {
        if (value.length > 0) { 
          this.loading.companies = true
          this.indexCarrier.useSkeleton = true
          if (lastCompanyAdded.organization_type.id !== 2) {          
            await this.getShippers(lastMKPAdded)
          } 
          await this.getCarriersByType(value, 'organization_id')
          this.loading.companies = false
          this.indexShipper.useSkeleton = false
        } else {
          this.formattedShipper = []
        }
      } 
      else {
        const deleteShippers = this.formattedShipper.filter(item => item.organization_id !== this.deleteShipperFromArray.info.id)
        this.formattedShipper = deleteShippers
        this.setSelectOptions('shipper_id', { options: this.formattedShipper })        
        if (value.length === 0) {
          this.form.shipper_id = null 
          this.setSelectOptions('carriers', { options: this.carriers })
        }
        else {
          this.indexCarrier.useSkeleton = true
          await this.getCarriersByType(value, 'organization_id')
        }
        this.deleteShipperFromArray.click = false 
        this.deleteShipperFromArray.info = null

      }
      this.keyFormRender++
      
    },
    onDeselect (data) {
      if (!!data) {
        this.deleteShipperFromArray.click = true 
        this.deleteShipperFromArray.info = data
      }
    },
    getCarriers () {
      this.indexCountry.disabled = true
      this.loading.companies = true
      this.indexCarrier.useSkeleton = true
      this.$store.dispatch('fetchService', { name: 'getCarriersNotifications', onSuccess: () => { this.indexCarrier.useSkeleton = false; this.loading.companies = false; this.indexCountry.disabled = false }})
    },
    changeCountry (name, value) {
      const originalCarriers = this.setSelectOptions('carriers', { options: this.carriers })
      this.form.carriers = null
      if (value !== null) {
        if (value.id !== '*') {
          const filterCarrierByCountry = this.carriers.filter(item => {
            return item.country_id === value.id
          })
          this.setSelectOptions('carriers', { options: filterCarrierByCountry })
        } else {
          return originalCarriers
        }
      } else {
        return originalCarriers
      } 
    },
    getShippers (value) {
      const queryParams = {}
      const params = {
        organization_id: value.id
      }
      this.indexShipper.useSkeleton = true
      return this.baseService.callService('getShipperFormattedNotifications', queryParams, params)
        .then(resp => {
          const old = this.formattedShipper
          const news = resp.data.map(el => ({...el, text: `${el.name} - ${value.name}`, organization_id: value.id}))
          const concatenated = old.concat(news)
          this.formattedShipper = concatenated
          this.setSelectOptions('shipper_id', { options: this.formattedShipper })
          this.keyFormRender++
        })
        .catch(err => {
          return err
        })
    },
    getCarriersByType (value, type) {
      const lastCompanyAdded = value[value.length - 1]
      const queryParams = {
        [`${type}`]: lastCompanyAdded.id
      }
      return this.baseService.callService('getCarriersOrganizationShippersNotifications', queryParams)
        .then(resp => {
          const response =  resp.data.map(el => ({...el.carrier, text: el.carrier.name}))
          const hash = {}
          const filterDuplicateCarriers = response.filter(o => hash[o.id] ? false : hash[o.id] = true)
          lastCompanyAdded.carriers = filterDuplicateCarriers
          let carriers = []
          this.indexCarrier.useSkeleton = false
          // this.form.carriers = null   
          carriers = this.carriers.filter(el => value.findIndex(company => company.carriers.map(carrier => carrier.code).includes(el.code)) !== -1).map(el => ({...el, text: el.name}))    
          this.setSelectOptions('carriers', { options: carriers })
        })
        .catch(err => {
          return err
        })
    },
    async changeShipper (name, value) {
      this.loading.shipper_id = true
      if (value.length > 0) { 
        this.loading.companies = true
        this.indexCarrier.useSkeleton = true
        await this.getCarriersByType(value, 'shipper_id')
        this.loading.companies = false
        this.loading.shipper_id = false
      }     
      this.keyFormRender++
    },
    keywordsByType (data) {
      const names = data.map(item => {
        const formatterItem = this.deleteDiacritics(item.name)
        return formatterItem.toLowerCase().split(' ')
      })
      const flatNames = names.flat()
      return flatNames
    }
  }
}
</script>
<style lang="scss">
.rule-dialog {
  .vs-dialog {
    height: 1900px;
  }
}
.companies .vs__selected, .shipper_id .vs__selected {
    background-color: #044389 !important;
}
.companies .vs__dropdown-menu, .shipper_id .vs__dropdown-menu{
    top: 2.5rem !important;
}
.v-select:not(.select-multiple) > .vs__dropdown-toggle > .vs__selected-options > .vs__selected {
    width: auto !important;
}
</style>